document.addEventListener("DOMContentLoaded", function() {
    // jQuery('#SubmitForm').on('click', function(e) {
    // 	e.preventDefault();

    initAdminUserEditor();

    function initAdminUserEditor() {
        document.querySelectorAll(".admin-open-user-edit-modal").forEach(el => {
            el.addEventListener("click", function(event) {
                $("#modal-admin-user-edit-title").text(
                    event.target.dataset.userName + " adatlapja"
                );
                loadUserData(event.target.dataset.userId);
            });
        });

        let toggleFahButton = document.querySelector(
            "#modal-admin-toggle-fah-save"
        );
        if (toggleFahButton) {
            toggleFahButton.addEventListener("click", function() {
                saveToggleFah();
            });
        }

        let submitBtn = document.querySelector("#modal-admin-user-edit-save");
        if (submitBtn) {
            submitBtn.addEventListener("click", function() {
                saveUserData();
            });
        }

        let updatePasswordBtn = document.querySelector(
            "#modal-admin-update-user-password"
        );
        if (updatePasswordBtn) {
            updatePasswordBtn.addEventListener("click", function() {
                updateUserPassword();
            });
        }
    }

    function clearSubscriptionForm() {
        //hide .has_subscription
        $(".has_subscription").hide();
        $("#plan_name").val("");
        $("#plan_price").val("");
        $("#plan_duration").val("");
        $("#modal-admin-user-subscribe #plan_name").empty();
        $("#modal-admin-user-subscribe #plan_duration").empty();
        $("#user_subscribe_update_error").text("");
        $("#user_subscribe_update_success").text("");
        $("#user_subscribe_update_error_unexpected").hide();
    }

    function loadUserData(userId) {
        clearErrors();
        $("#modal-admin-user-edit-fields input").each(function() {
            $(this).val(null);
        });
        $("#new_password").val(null),
            $("#new_password_confirmation").val(null),
            $("#new_password_error").text(null),
            $("#new_password_confirmation_error").text(null),
            $("#user-id").val(userId);

        $.ajax({
            url: $("#admin-ajax-get-user-data-url").val(),
            type: "GET",
            data: {
                _token: $("#csrf-token").val(),
                userId: userId
            },
            success: function(response) {
                if (response.error) {
                    return;
                }
                for (let key in response.data) {
                    $("#" + key).val(response.data[key]);
                }

                if (response.dataToggleFah["is_artist_or_fah"] === true) {
                    $("#toggleFah").removeClass("d-none");
                } else {
                    $("#toggleFah").addClass("d-none");
                }

                for (let key in response.dataToggleFah) {
                    $("#" + key).prop("checked", response.dataToggleFah[key]);
                }

                $("#modal-admin-user-edit").modal("show");
            },
            error: function(response) {}
        });
    }

    function saveToggleFah() {
        $.ajax({
            url: $("#admin-ajax-user-toggle-fah-url").val(),
            type: "POST",
            data: {
                _token: $("#csrf-token").val(),
                user_id: $("#user-id").val(),
                is_fah: $("#is_fah").prop("checked"),
                has_fah_subscription: $("#has_fah_subscription").prop("checked")
            },
            success: function(response) {
                if (response.error) {
                    $("#user_fah_toggle_error").text(response.message);
                    return;
                }

                if (!response.error && !response.validator) {
                    $("#user_fah_toggle_success").text(response.message);
                }

                location.reload(); //This line eradicates the whole point of the ajax method, but it's needed to show an up to date list for the admin.
            },
            error: function(response) {
                $("#user_fah_toggle_error_unexpected").show();
            }
        });
    }

    function saveUserData() {
        clearErrors();

        $.ajax({
            url: $("#admin-ajax-user-data-update-url").val(),
            type: "POST",
            data: {
                _token: $("#csrf-token").val(),
                user_id: $("#user-id").val(),
                phone_number: $("#phone_number").val(),

                zip_code: $("#zip_code").val(),
                country: $("#country").val(),
                city: $("#city").val(),
                address: $("#address").val(),

                last_name: $("#last_name").val(),
                first_name: $("#first_name").val(),
                company_name: $("#company_name").val(),
                tax_number: $("#tax_number").val(),

                billing_zip_code: $("#billing_zip_code").val(),
                billing_country: $("#billing_country").val(),
                billing_city: $("#billing_city").val(),
                billing_address: $("#billing_address").val(),
                bank_account_number: $("#bank_account_number").val()
            },
            success: function(response) {
                if (response.error) {
                    $("#user_data_update_error").text(response.message);
                    return;
                }

                for (let key in response.validator) {
                    $("#" + key + "_error").html(
                        response.validator[key].join("<br>")
                    );
                }

                if (!response.error && !response.validator) {
                    $("#user_data_update_success").text(response.message);
                }
            },
            error: function(response) {
                $("#user_data_update_error_unexpected").show();
            }
        });
    }

    function updateUserPassword() {
        clearErrors();
        $.ajax({
            url: $("#admin-ajax-update-user-password-url").val(),
            type: "POST",
            data: {
                _token: $("#csrf-token").val(),
                user_id: $("#user-id").val(),

                new_password: $("#new_password").val(),
                new_password_confirmation: $("#new_password_confirmation").val()
            },
            success: function(response) {
                if (typeof response === "string") {
                    response = JSON.parse(response);
                }
                if (response.error) {
                    $("#user_data_update_error").text(response.message);
                    return;
                }

                for (let key in response.validator) {
                    $("#" + key + "_error").html(
                        response.validator[key].join("<br>")
                    );
                }

                if (!response.error && !response.validator) {
                    $("#user_data_update_success").text(response.message);
                }
            },
            error: function(response) {
                $("#user_data_update_error_unexpected").show();
            }
        });
    }

    function clearErrors() {
        $(".modal-admin-user-edit-error").each(function() {
            $(this).text(null);
        });
        $("#user_data_update_error").text(null);
        $("#user_data_update_error_unexpected").hide();
        $("#user_data_update_success").text(null);
        $("#user_fah_toggle_error").text(null);
        $("#user_fah_toggle_success").text(null);
        $("#user_fah_toggle_error_unexpected").hide();
    }
});
